<template>
<div id="Login" class="pb-5 mb-5">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<v-container class="d-flex align-center justify-center animated fadeIn pb-5 mb-5">
   <v-card class="pa-5 elevation-5">
        <v-card-title><p class="display-2">Login!</p></v-card-title>
        <v-card-text>
        <form @submit.prevent="Submit">
            <v-text-field v-model="email" type="email" label="Email" required >
            </v-text-field>
            <v-text-field v-model="password" :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'" name="password"
            label="Password" hint="At least 8 characters" counter @click:append="showpass = !showpass"
            required></v-text-field>
            <div class="d-flex align-center justify-center pa-5">
              <v-btn type="submit" fab bottom absolute class="primary elevation-10">
                <v-icon :class="{'mdi-spin' : disbtn}">mdi-telegram</v-icon>
              </v-btn>
            </div>
        </form>
        </v-card-text>
   </v-card>
</v-container>
</div>
</template>

<script>
// imports
import firebase from "firebase/app";
import "firebase/auth";

// scripts
export default {

    name: "login",

    data(){
     return{
        email: '',
        password: '',
         // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',showpass: false,disbtn: false,
     }
    },

    methods: {

      // login code 
      Submit () {
          if(!this.BlankValidation()){return false;}this.disbtn = true;
          firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(
            (res) => {
                if(res.user.emailVerified){
                 this.disbtn = false;
                 this.$router.replace({name: "home"});
                 this.$store.dispatch('getUserClaims');
                }else{
                 this.disbtn = false;
                 this.notificationService("Sorry, Your Email Is Not Verified, Please Verify To Proceed!", "info");
                 res.user.sendEmailVerification().then((succ) => {
                   firebase.auth().signOut();
                 }).catch((err) => 
                 {
                   this.disbtn = false;
                   this.notificationService("Your Account Seems Fishy, Contact Admin!", "red darken-1");
                   firebase.auth().signOut();
                 });
                }
            },(error) => {
                if(error.code == "auth/user-not-found"){
                   this.notificationService("Invalid Credentials!", "red darken-1");
                }else if(error.code == "auth/wrong-password"){
                   this.notificationService("Wrong Password!", "red darken-1");
                }
                 this.disbtn = false;
            });
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.email == '' || this.password == '')
        {this.notificationService("Fill All Fields!", "red darken-1"); this.disbtn = false;return false;}else{return true;}
      },

       // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },
    },
}
</script>

<style scoped>
#Login{
  height: 100%;
}
#Login .v-card{
  width: 50%;
  margin-top: 5%;
}
@media (max-width: 900px) {
  #Login .v-card{
    width: 100%;
    margin-top: 5%;
  }
}
</style>