import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta'
import router from './router';
import Vuelidate from "vuelidate";
import './assets/animatecss/animate.css';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from "firebase/app";
import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(CKEditor);
Vue.use(VueMeta);

// Your web app's Firebase configuration
var firebaseConfig = {
  // see .env file for instructions
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'api-key-not-set',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'env-not-set',
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || 'env-not-set',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || 'env-not-set',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || 'env-not-set',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || 'env-not-set',
  appId:  process.env.VUE_APP_FIREBASE_APP_ID || 'env-not-set',
  measurementId:  process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || 'env-not-set'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence()
.catch(function(err) {
    if (err.code == 'failed-precondition') {
        console.log("failed precondition for firebase firestore")
    } else if (err.code == 'unimplemented') {
        console.log('This browser doesn\'t support offline firestore')
    }
});

let app;

firebase.auth().onAuthStateChanged(user => {
  if(!app){
    app = new Vue({
      store,
      router,
      vuetify,
      validations: {},
      render: h => h(App)
    }).$mount('#app')
  }
});

