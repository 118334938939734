import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import firebase from "firebase/app";
import "firebase/auth";
// basic (loaded normally)
import Welcome from '../views/Welcome.vue';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
// users (loaded dynamically)
const Users = () => import(/* webpackChunkName: "users" */ '../views/Users/Users.vue');
const AddUser = () => import(/* webpackChunkName: "addUser" */ '../views/Users/AddUser.vue');
const EditUser = () => import(/* webpackChunkName: "editUser" */ '../views/Users/EditUser.vue');
const UserDetails = () => import(/* webpackChunkName: "userDetails" */ '../views/Users/UserDetails.vue');
// students
const Students = () => import(/* webpackChunkName: "students" */ '../views/Students/Students.vue');
const studentDetails = () => import(/* webpackChunkName: "studentDetails" */ '../views/Students/StudentDetails.vue');
const AddStudent = () => import(/* webpackChunkName: "addStudent" */ '../views/Students/AddStudent.vue');
const EditStudent = () => import(/* webpackChunkName: "editStudent" */ '../views/Students/EditStudent.vue');
// courses
const Courses = () => import(/* webpackChunkName: "courses" */ '../views/Courses/Courses.vue');
const CourseDetails = () => import(/* webpackChunkName: "courseDetails" */ '../views/Courses/CourseDetails.vue');
const CourseWelcome = () => import(/* webpackChunkName: "courseWelcome" */ '../views/Courses/CourseWelcome.vue');
const AddCourse = () => import(/* webpackChunkName: "addCourse" */ '../views/Courses/AddCourse.vue');
const EditCourse = () => import(/* webpackChunkName: "editCourse" */ '../views/Courses/EditCourse.vue');
// posts
const Posts = () => import(/* webpackChunkName: "posts" */ '../views/Posts/Posts.vue');
const PostList = () => import(/* webpackChunkName: "postList" */ '../views/Posts/PostList.vue');
const PostWelcome = () => import(/* webpackChunkName: "postWelcome" */ '../views/Posts/PostWelcome.vue');
const PostDetails = () => import(/* webpackChunkName: "postDetails" */ '../views/Posts/PostDetails.vue');
const AddPost = () => import(/* webpackChunkName: "addPost" */ '../views/Posts/AddPost.vue');
const EditPost = () => import(/* webpackChunkName: "editPost" */ '../views/Posts/EditPost.vue');
// events
const Events = () => import(/* webpackChunkName: "events" */ '../views/Events/Events.vue');
const EventList = () => import(/* webpackChunkName: "eventList" */ '../views/Events/EventList.vue');
const EventWelcome = () => import(/* webpackChunkName: "eventWelcome" */ '../views/Events/EventWelcome.vue');
const EventDetails = () => import(/* webpackChunkName: "eventDetails" */ '../views/Events/EventDetails.vue');
const AddEvent = () => import(/* webpackChunkName: "addEvent" */ '../views/Events/AddEvent.vue');
const EditEvent = () => import(/* webpackChunkName: "editEvent" */ '../views/Events/EditEvent.vue');
// gallery
const GalleryHome = () => import(/* webpackChunkName: "galleryHome" */ '../views/Gallery/GalleryHome.vue');
const GalleryOverview = () => import(/* webpackChunkName: "galleryOverview" */ '../views/Gallery/GalleryOverview.vue');
const GalleryDetails = () => import(/* webpackChunkName: "galleryDetails" */ '../views/Gallery/GalleryDetails.vue');
// student extra
const Marks = () => import(/* webpackChunkName: "marks" */ '../views/StudentExtra/Marks.vue');
const Awards = () => import(/* webpackChunkName: "awards" */ '../views/StudentExtra/Awards.vue');
const AddAward = () => import(/* webpackChunkName: "addAward" */ '../views/StudentExtra/AddAward.vue');
const GiveAward = () => import(/* webpackChunkName: "giveAward" */ '../views/StudentExtra/GiveAward.vue');
const EditAward = () => import(/* webpackChunkName: "editAward" */ '../views/StudentExtra/EditAward.vue');
const AwardDetails = () => import(/* webpackChunkName: "awardDetails" */ '../views/StudentExtra/AwardDetails.vue');
const AwardWelcome = () => import(/* webpackChunkName: "awardWelcome" */ '../views/StudentExtra/AwardWelcome.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    name: 'welcome',
    component: Welcome,
    meta: {
      guest: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/galleryhome',
    name: 'galleryhome',
    component: GalleryHome,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/galleryoverview',
    name: 'galleryoverview',
    component: GalleryOverview,
    meta: {
      guest: true,
    }
  },
  {
    path: '/gallerydetails/:galleryslug',
    name: 'gallerydetails',
    component: GalleryDetails,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/userdetails/:uid',
    name: 'userdetails',
    component: UserDetails,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/edituser/:uid',
    name: 'edituser',
    component: EditUser,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/adduser',
    name: 'adduser',
    component: AddUser,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/students',
    name: 'students',
    component: Students,
    meta: {
      auth: true
    }
  },
  {
    path: '/addstudent',
    name: 'addstudent',
    component: AddStudent,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/editstudent/:id',
    name: 'editstudent',
    component: EditStudent,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/studentdetails/:id',
    name: 'studentdetails',
    component: studentDetails,
    meta: {
      auth: true
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses,
    meta: {
      auth: true,
    }
  },
  {
    path: '/addcourse',
    name: 'addcourse',
    component: AddCourse,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/editcourse/:courseslug',
    name: 'editcourse',
    component: EditCourse,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/coursedetails/:courseslug',
    name: 'coursedetails',
    component: CourseDetails,
    meta: {
      auth: true,
    }
  },
  {
    path: '/posts',
    name: 'posts',
    component: Posts,
    meta: {
      auth: true,
    }
  },
  {
    path: '/articles',
    name: 'postlist',
    component: PostList,
    meta: {
      guest: true,
    }
  },
  {
    path: '/addpost',
    name: 'addpost',
    component: AddPost,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/editpost/:slug',
    name: 'editpost',
    component: EditPost,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/postdetails/:slug',
    name: 'postdetails',
    component: PostDetails,
    meta: {
      auth: true
    }
  },
  {
    path: '/articleoverview/:slug',
    name: 'postwelcome',
    component: PostWelcome,
    meta: {
      guest: true
    }
  },
  {
    path: '/courseoverview/:courseslug',
    name: 'coursewelcome',
    component: CourseWelcome,
    meta: {
      guest: true
    }
  },
  {
    path: '/eventoverview/:eventslug',
    name: 'eventwelcome',
    component: EventWelcome,
    meta: {
      guest: true
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      auth: true,
    }
  },
  {
    path: '/newsandevents',
    name: 'eventlist',
    component: EventList,
    meta: {
      guest: true,
    }
  },
  {
    path: '/addevent',
    name: 'addevent',
    component: AddEvent,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/editevent/:eventslug',
    name: 'editevent',
    component: EditEvent,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/eventdetails/:eventslug',
    name: 'eventdetails',
    component: EventDetails,
    meta: {
      auth: true
    }
  },
  {
    path: '/marks/:id',
    name: 'marks',
    component: Marks,
    meta: {
      auth: true
    }
  },
  {
    path: '/giveaward/:id',
    name: 'giveaward',
    component: GiveAward,
    meta: {
      auth: true
    }
  },
  {
    path: '/awards',
    name: 'awards',
    component: Awards,
    meta: {
      auth: true,
    }
  },
  {
    path: '/addaward',
    name: 'addaward',
    component: AddAward,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/awarddetails/:awardslug',
    name: 'awarddetails',
    component: AwardDetails,
    meta: {
      auth: true,
    }
  },
  {
    path: '/editaward/:awardslug',
    name: 'editaward',
    component: EditAward,
    meta: {
      auth: true,
      admin: true,
    }
  },
  {
    path: '/awardoverview/:quarter',
    name: 'awardoverview',
    component: AwardWelcome,
    meta: {
      guest: true,
    }
  },
  {
    path: '*', 
    redirect: { name: 'welcome' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  store.dispatch('getUserClaims');
  const auth = to.matched.some(record => record.meta.auth);
  const guest = to.matched.some(record => record.meta.guest);
  const admin = to.matched.some(record => record.meta.admin);
  const isAuthenticated = firebase.auth().currentUser;
  const isAdmin = store.getters.UserIsAdmin;
  // if not authenticated go to login else stay
  auth && !isAuthenticated ? next("/login") : next();
  // if authentictaed, stay
  guest && isAuthenticated ? next("/home") : next();
  // if not admin, just go to home : - D
  admin && !isAdmin ? next("/home") : next();
})

export default router
