<template>
<!-- these 2 tags are must to enable vuetify styles -->
<v-app> 
<v-content>

  <top-header></top-header>
    <wrapper-component></wrapper-component>
  <bottom-footer></bottom-footer>

</v-content>
</v-app>
</template>

<script>
// imports
import TopHeader from './components/layouts/Top-Header';
import wrapperComponent from './components/layouts/WrapperComponent';
import BottomFooter from './components/layouts/Bottom-Footer';


// scripts
export default {

  name: 'app',

  metaInfo: {
    title: "Kalalaya Academy",
    titleTemplate: '%s - Best Educational Institution In Ottapalam',
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills" },
      { name: "keywords", content: "tution, dance, spoken-english, ottapalam, meetna, best-tution-center, free, interview, resume" },
      // facebook
      { property: "og:title", content: "Kalalaya Academy Facebook" },
      { property: "og:description", content: "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills" },
      { property: "og:url", content: "https://www.facebook.com/pages/category/Education/Kalalaya-Academy-100118604819166/" },
      // instagram
      { property: "og:title", content: "Kalalaya Academy Instagram" },
      { property: "og:description", content: "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills" },
      { property: "og:url", content: "https://instagram.com/kalalaya_academy?igshid=rhwoss8hokdk" },
   ],
    link: [
      {rel: 'canonical', href: 'https://www.kalalayaacademy.com'}
   ]
  },

  mounted(){
    this.$store.dispatch('getCourses');
    this.$store.dispatch('getEvents');
    this.$store.dispatch('getPosts');
    this.$store.dispatch('getGallery');
    this.$store.dispatch('getAwards');
    this.$store.dispatch('getStudentAwards');
    this.$store.dispatch('fetchStudentData');
    this.$store.dispatch('fetchUserData');
    this.$store.dispatch('getUserClaims');
    this.$store.dispatch('getMarks');
    this.$store.dispatch('getComments');
  },

  components: {
    'top-header': TopHeader,
    'wrapper-component': wrapperComponent,
    'bottom-footer': BottomFooter
  },

  data(){
    return{

    }
  }

};
</script>

<style>
/* font faces */

/* galada-regular - latin */
@font-face {
  font-family: 'Galada';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/galada-v5-latin/galada-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Galada Regular'), local('Galada-Regular'),
       url('assets/fonts/galada-v5-latin/galada-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/galada-v5-latin/galada-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/galada-v5-latin/galada-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/galada-v5-latin/galada-v5-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
       url('assets/fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* font faces end */

/* styles */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0; 
}
html,body{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}
#app{  
 text-shadow: 1px 1px 3px rgba(0,0,0,0.005) !important;
 text-rendering: optimizeLegibility !important;
 -webkit-font-smoothing: antialiased !important;
 font-family: 'Noto Sans', 'Arial', -apple-system, BlinkMacSystemFont, 
 "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", 
 "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 height: 100%; width: 100%;
 width: 100%;
 margin: 0;
 padding: 0;
}
.bg-accent-card{
  height: 300px;
  border-radius: 0;
  color: white;
}
.ck-editor__editable {
    height: 150px !important;
}
</style>