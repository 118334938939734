import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";

Vue.use(Vuex);

export default new Vuex.Store({

  // data
  state: {
    users: [],
    students: [],
    awards: [],
    studentAwards: [],
    marks: [],
    courses: [],
    posts: [],
    events: [],
    gallery: [],
    comments: [],
    isAdmin: false,
    isTeacher: false
  },

  // mutations   
  mutations: {
      // change data
      SET_USER_DATA(state, users){ state.users = users },   
      SET_STUDENT_DATA(state, students){ state.students = students },   
      SET_AWARD_DATA(state, awards){ state.awards = awards },   
      SET_STUDENT_AWARD_DATA(state, studentAwards){ state.studentAwards = studentAwards },   
      SET_MARK_DATA(state, marks){ state.marks = marks },   
      SET_COURSE_DATA(state, courses){ state.courses = courses },   
      SET_POST_DATA(state, posts){ state.posts = posts },   
      SET_EVENT_DATA(state, events){ state.events = events },   
      SET_GALLERY_DATA(state, gallery){ state.gallery = gallery },   
      SET_COMMENT_DATA(state, comments){ state.comments = comments },   
      SET_ADMIN_CLAIM(state, claim){ state.isAdmin = claim },
      SET_TEACHER_CLAIM(state, claim){ state.isTeacher = claim }
  },

  // actions
  actions: {

      // get user claim
      getUserClaims(context){
        firebase.auth().onAuthStateChanged((user) => {
          if(!user){return false;}
          user.getIdTokenResult().then((succ) => {
            let admin = succ.claims.isAdmin ? true : false ;
            let teacher = succ.claims.isTeacher ? true : false;
            context.commit('SET_ADMIN_CLAIM', admin);
            context.commit('SET_TEACHER_CLAIM', teacher);
          });
        });
      },

      // fetch student data from server
      async fetchStudentData(context){
        let tmpStudentArray = [];
        await firebase.firestore().collection("studentDetails").orderBy("addedAt", "asc").get().then(
          (doc) => { 
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){   
                tmpStudentArray.push(val.data());           
              }
            });
            // push student data
            context.commit('SET_STUDENT_DATA', tmpStudentArray);
          }, (err) => { console.log(err); });
      },

      // fetch student data from server
      async fetchUserData(context){
        let tmpUserArray = [];
        await firebase.firestore().collection("users").get().then(
          (doc) => { 
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){   
                tmpUserArray.push(val.data());           
              }
            });
            // push student data
            context.commit('SET_USER_DATA', tmpUserArray);
          }, (err) => { console.log(err); });
      },

      // get awards from database
      async getAwards(context){
        let tmpAwardArray = [];
        await firebase.firestore().collection("awards").get().then(
         (doc) => {
           doc.forEach(val => { 
             if(val.id, " => ", val.data()){ 
              tmpAwardArray.push(val.data()); 
             }
           });
           // push course data
           context.commit('SET_AWARD_DATA', tmpAwardArray);
         }, (err) => { console.log(err); });
      },

      // get awards from database
      async getStudentAwards(context){
        let tmpStudentAwardArray = [];
        await firebase.firestore().collection("studentAwards").get().then(
         (doc) => {
           doc.forEach(val => { 
             if(val.id, " => ", val.data()){ 
              tmpStudentAwardArray.push(val.data()); 
             }
           });
           // push course data
           context.commit('SET_STUDENT_AWARD_DATA', tmpStudentAwardArray);
         }, (err) => { console.log(err); });
      },

      // get marks from database
      async getMarks(context){
        let tmpMarkArray = [];
        await firebase.firestore().collection("marks").get().then(
         (doc) => {
           doc.forEach(val => { 
             if(val.id, " => ", val.data()){ 
              tmpMarkArray.push(val.data()); 
             }
           });
           // push course data
           context.commit('SET_MARK_DATA', tmpMarkArray);
         }, (err) => { console.log(err); });
      },

      // get courses from database
      async getCourses(context){
        let tmpCourseArray = [];
        await firebase.firestore().collection("courses").get().then(
         (doc) => {
           doc.forEach(val => { 
             if(val.id, " => ", val.data()){ 
               tmpCourseArray.push(val.data()); 
             }
           });
           // push course data
           context.commit('SET_COURSE_DATA', tmpCourseArray);
         }, (err) => { console.log(err); });
      },

      // get posts from database
      async getPosts(context){
        let tmpPostArray = [];
        await firebase.firestore().collection("posts").orderBy("addedAt", "desc").get().then(
          (doc) => {
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){ 
                tmpPostArray.push(val.data()); 
              }
            });
            // push course data
            context.commit('SET_POST_DATA', tmpPostArray);
          }, (err) => { console.log(err); });
      },

      // get events from database
      async getEvents(context){
        let tmpEventArray = [];
        await firebase.firestore().collection("events").orderBy("addedAt", "desc").get().then(
          (doc) => {
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){ 
                tmpEventArray.push(val.data()); 
              }
            });
            // push course data
            context.commit('SET_EVENT_DATA', tmpEventArray);
          }, (err) => { console.log(err); });
      },

      // get gallery images from database
      async getGallery(context){
        let tmpGalleryArray = [];
        await firebase.firestore().collection("gallery").orderBy("addedAt", "desc").get().then(
          (doc) => {
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){ 
                tmpGalleryArray.push(val.data()); 
              }
            });
            // push gallery data
            context.commit('SET_GALLERY_DATA', tmpGalleryArray);
          }, (err) => { console.log(err); });
      },

      // get comments from database
      async getComments(context){
        let tmpCommentArray = [];
        await firebase.firestore().collection("comments").orderBy("addedAt", "desc").get().then(
          (doc) => {
            doc.forEach(val => { 
              if(val.id, " => ", val.data()){ 
              tmpCommentArray.push(val.data()); 
              }
            });
            // push comment data
            context.commit('SET_COMMENT_DATA', tmpCommentArray);
          }, (err) => { console.log(err); });
      },

  },

  // getters
  getters: {

    // get students
    GetCurrentUserData: state => state.users.filter(user => user.uid == firebase.auth().currentUser.uid),

    // get students
    GetAllUsers: state => state.users.filter(user => user.uid != firebase.auth().currentUser.uid),

    // get user with matching Id
    GetUserWithID: (state) => (Id) => state.users.filter(user => user.uid == Id),

    // get user claims
    UserIsAdmin: state => state.isAdmin,
    UserIsTeacher: state => state.isTeacher,

    // get students
    GetAllStudents: state => state.students,

    // get awards
    GetAllAwards: state => state.awards,

    // get student awards
    GetStudentAwards: state => state.studentAwards,

    // get award with matching slug
    GetAwardWithSlug: (state) => (awardslug) => state.awards.filter(award => award.awardslug == awardslug),

    // get award with matching id
    GetStudentAwardWithID: (state) => (id) => state.studentAwards.filter(award => award.studentID == id),

    // get award with matching slug
    GetStudentAwardWithSlug: (state) => (awardslug) => state.studentAwards.filter(award => award.awardslug == awardslug),

    // get award with matching quarter
    GetStudentAwardWithQuarter: (state) => (quarter) => state.studentAwards.filter(award => award.quarter == quarter),

    // get award with matching quarter
    GetStudentAwardWithMonth: (state) => (month) => state.studentAwards.filter(award => award.month == month),

    // get marks
    GetAllMarks: state => state.marks,

    // get mark with matching Id
    GetMarkWithID: (state) => (Id) => state.marks.filter(marks => marks.studentID == Id),
    
    // get students with tution class
    StudentsOnCourse: (state) => (course) => state.students.filter(student => student.course == course),

    // get student with matching Id
    GetStudentWithID: (state) => (Id) => state.students.filter(student => student.studentID == Id),

    // get courses
    GetAllCourses: state => state.courses,

    // get course with matching slug
    GetCourseWithSlug: (state) => (courseslug) => state.courses.filter(course => course.courseslug == courseslug),

    // get posts
    GetAllPosts: state => state.posts,

    // get post with matching slug
    GetPostWithSlug: (state) => (slug) => state.posts.filter(post => post.slug == slug),

    // get events
    GetAllEvents: state => state.events,

    // get gallery
    GetGallery: state => state.gallery,

    // get gallery with slug
    GetGalleryWithSlug: (state) => (galleryslug) => state.gallery.filter(gallery => gallery.galleryslug == galleryslug),

    // get 3 event - past
    GetPastEvents: state => state.events.filter(event => event.status == 'Past'),

    // get 3 upcoming events
    GetUpcomingEvents: state => state.events.filter(event => event.status == 'Upcoming'),

    // get event with matching slug
    GetEventWithSlug: (state) => (eventslug) => state.events.filter(event => event.eventslug == eventslug),

    // get comments with matching slug
    GetCommentsWithSlug: (state) => (slug) => state.comments.filter(comment => comment.slug == slug),

    // get only 2 comments
    GetCommentsWithSlugSmall: (state) => (slug) => state.commentsSmall.filter(comment => comment.slug == slug),

  }


});
