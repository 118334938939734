import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify)

export default new Vuetify({
    theme: {

        themes: {
            light: {
                primary: '#770E4F',
                secondary: '#1A1043',
                accent: '#BF0085',
                error: '#FF5252',
                info: '#2196F3',
                success: colors.teal.darken3,
                warning: '#FFC107',
                dataTableBg: '#efebf7',
                cardGrey: colors.grey.lighten5,
            },
        },
    }
})