<template>
<div class="home" v-if="UserData != undefined || UserData != null">
<div class="d-flex mb-5 justify-center align-center py-5 px-5" style="flex-direction: column;margin-top: 1rem">
  

  <div class="white animated fadeIn slower pa-3 mb-5 d-flex elevation-5 justify-center align-center" style="height: 170px;width: 170px; border-radius: 100px">
    <v-img v-if="!UserData.photoURL" class="elevation-8" src="../assets/images/profile-pic.svg" alt="Profile Pic" style="height: 150px; border-radius: 100px" rel="preload"></v-img>
    <v-img v-else :src="UserData.photoURL" class="elevation-8" alt="Profile Pic" style="height: 150px; border-radius: 100px" rel="preload">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>

  <div class="text-center">
    <p class="headline">Hi {{UserData.displayName}},</p>
    <p class="headline">What's On Your Mind?</p>
  </div>

  <div v-if="UserIsAdmin" class="pt-5 mb-5 px-5 d-flex justify-center align-center" style="flex-direction: column">
    <v-row>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/addevent')" block rounded large class="elevation-5 primary">Add Event</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/addpost')" block dark rounded large class="elevation-5 deep-purple">Add Article</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/')" block rounded large class="elevation-5 info darken-1">Add Award</v-btn></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/adduser')" block dark rounded large class="elevation-5 teal">Add User</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/addstudent')" block rounded large class="elevation-5 success">Add Student</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/addcourse')" block dark rounded large class="elevation-5 indigo">Add Course</v-btn></v-col>
    </v-row>
    <v-btn fab small bottom right class="accent mt-5 elevation-5"><v-icon>mdi-pencil</v-icon></v-btn>
  </div>

  <div v-if="UserIsTeacher" class="pt-5 mb-5 px-5 d-flex justify-center align-center" style="flex-direction: column">
    <v-row>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/')" block rounded large class="elevation-5 primary">Show Events</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/posts')" block dark rounded large class="elevation-5 deep-purple">Show Articles</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/')" block rounded large class="elevation-5 info darken-1">Show Awards</v-btn></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/users')" block dark rounded large class="elevation-5 teal">Show Users</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/students')" block rounded large class="elevation-5 success">Show Students</v-btn></v-col>
      <v-col cols="12" sm="12" md="4"><v-btn @click="$router.push('/courses')" block dark rounded large class="elevation-5 indigo">Show Courses</v-btn></v-col>
    </v-row>
    <v-btn fab small bottom right class="accent mt-5 elevation-5"><v-icon>mdi-pencil</v-icon></v-btn>
  </div>

</div>
</div>
</template>

<script>
// scripts
export default {

  name: 'home',
  
  components: {
   
  },

  computed:{
      UserIsAdmin(){
        return this.$store.getters.UserIsAdmin;
      },
      UserIsTeacher(){
        return this.$store.getters.UserIsTeacher;
      },
      UserData(){
        return this.$store.getters.GetCurrentUserData[0];
      }
  },

  data(){
    return{
     
    }
  },

  methods: {
   
  }

}
</script>
