<template>
<div id="BottomFooter" class="primary">
  <v-card dark class="secondary py-5 d-flex align-center justify-center ">
    <v-container style="margin: 2.5rem auto">
    <v-footer absolute dark class="font-weight-medium my-5 secondary" style="border-radius: 0">
      <v-col class="text-center" cols="12" >
       <p> &copy; {{ new Date().getFullYear() }} — Kalalaya Academy</p>
      </v-col>
    </v-footer>
    </v-container>
  </v-card>
</div>
</template>

<script>

// scripts
export default {

    name: 'BottomFooter',

    
}
</script>
