<template>
  <div class="welcome">
    <!-- showcase -->
    <section id="showCase" class="elevation-5 animated fadeIn slower">
      <v-row>
        <!-- main pic -->
        <v-col cols="12" sm="12" md="6">
          <div id="Left" class="text-center">
            <div>
              <img id="Kimg" src="../assets/images/kalalaya-pic.png" alt="Kalalaya" />
            </div>
          </div>
        </v-col>
        <!-- side pics -->
        <v-col cols="12" sm="12" md="6">
          <div id="Right" class="text-center">
            <div id="SecondPic"></div>
            <div id="FirstPic"></div>
          </div>
        </v-col>
      </v-row>
      <!-- scroll down -->
      <div class="text-center" id="FabWelcome">
        <v-btn
          fab
          dark
          class="primary animated heartBeat infinite slow"
          @click="$vuetify.goTo('#FirstContainer', {duration: 20})"
        >
          <v-icon>mdi-chevron-double-down</v-icon>
        </v-btn>
      </div>
    </section>

    <!-- welcome board -->
    <div id="FirstContainer" class="grey lighten-4">
      <div class="d-flex align-center justify-center timg">
        <img src="../assets/images/kalalaya-pic.png" alt />
        <p class="my-5 text-center display-3">Welcomes You!</p>
      </div>
    </div>

    <!-- STATIC COMPONENTS -->
    <!-- tagline -->
    <tagline></tagline>
    <!-- about us -->
    <aboutus></aboutus>
    <!-- exam -->
    <exam></exam>
    <!-- courses -->
    <courselist></courselist>
    <!-- events -->
    <newslist></newslist>
    <!-- awards -->
    <awardlist></awardlist>
    <!-- articlelist -->
    <articlelist></articlelist>
    <!-- modes of learning -->
    <modesoflearning></modesoflearning>
    <!-- testimonials -->
    <testimonal></testimonal>
    <!-- gallery -->
    <gallerylist></gallerylist>
    <!-- contact us -->
    <contactus></contactus>
  </div>
</template>

<script>
// imports (dynamic)
const exam = () =>
  import(/* webpackChunkName: "exam" */ "../components/welcome/Exam");
const tagline = () =>
  import(/* webpackChunkName: "tagline" */ "../components/welcome/Tagline");
const aboutus = () =>
  import(/* webpackChunkName: "aboutus" */ "../components/welcome/AboutUs");
const newslist = () =>
  import(/* webpackPreload: true */ "../components/welcome/NewsList");
const awardlist = () =>
  import(/* webpackChunkName: "awardlist" */ "../components/welcome/AwardList");
const contactus = () =>
  import(/* webpackChunkName: "contactus" */ "../components/welcome/ContactUs");
const testimonal = () =>
  import(
    /* webpackChunkName: "testimonal" */ "../components/welcome/Testimonal"
  );
const courselist = () =>
  import(/* webpackPreload: true */ "../components/welcome/CourseList");
const articlelist = () =>
  import(
    /* webpackChunkName: "articlelist" */ "../components/welcome/ArticleList"
  );
const gallerylist = () =>
  import(
    /* webpackChunkName: "gallerylist" */ "../components/welcome/GalleryList"
  );
const modesoflearning = () =>
  import(
    /* webpackChunkName: "modesoflearning" */ "../components/welcome/ModesOfLearning"
  );
// scripts
export default {
  metaInfo: {
    title: "Kalalaya Academy",
    titleTemplate: "%s - Best Educational Institution In Ottapalam",
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills"
      },
      {
        name: "keywords",
        content:
          "tution, dance, spoken-english, ottapalam, meetna, best-tution-center, free, interview, resume"
      },
      // facebook
      { property: "og:title", content: "Kalalaya Academy Facebook" },
      {
        property: "og:description",
        content:
          "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills"
      },
      {
        property: "og:url",
        content:
          "https://www.facebook.com/pages/category/Education/Kalalaya-Academy-100118604819166/"
      },
      // instagram
      { property: "og:title", content: "Kalalaya Academy Instagram" },
      {
        property: "og:description",
        content:
          "Institution spoken English classes dance classes music classes interview programs job ready programs best tuition centre maths science English Hindi social physics chemistry biology resume biodata CV Ottapalam Palakkad Manikandan PK papitha Manikandan Keerthiveettil kayarampara near KV best number one educational Amrutha classes yes I can soft skills"
      },
      {
        property: "og:url",
        content: "https://instagram.com/kalalaya_academy?igshid=rhwoss8hokdk"
      }
    ],
    link: [{ rel: "canonical", href: "https://www.kalalayaacademy.com" }]
  },

  components: {
    testimonal,
    tagline,
    modesoflearning,
    exam,
    courselist,
    aboutus,
    articlelist,
    newslist,
    contactus,
    gallerylist,
    awardlist
  }
};
</script>

<style scoped>
.welcome {
  height: 100%;
  width: 100%;
}
#showCase {
  min-height: 800px;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: linear-gradient(
      rgba(245, 245, 255, 0.8),
      rgba(245, 245, 255, 0.8)
    ),
    /* bottom, image */ url("../assets/images/showcase-pic.jpg");
  text-shadow: 2px 7px 25px rgba(0, 0, 0, 0.7);
}

#showCase #Left {
  padding: 2vw;
  padding-top: 15%;
}
#showCase #Left img {
  width: 100%;
  height: 250px;
}
#showCase #Right {
  display: flex;
  justify-content: center;
  padding: 2vw;
  margin-bottom: 5vw;
}
#FirstPic {
  -moz-transform: skew(10deg, -25deg);
  -webkit-transform: skew(10deg, -25deg);
  -o-transform: skew(10deg, -25deg);
  -ms-transform: skew(10deg, -25deg);
  transform: skew(10deg, -25deg);
  background-color: #fff;
  height: 200px !important;
  width: 35% !important;
  border-radius: 20px;
  margin-top: 6rem;
  background-image: url("../assets/images/lpic-1.jpg");
  background-size: cover;
  background-position: top;
  border: 2px solid #eee;
  opacity: 0.8;
  -webkit-box-shadow: -14px 20px 21px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -14px 20px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: -14px 20px 21px -10px rgba(0, 0, 0, 0.75);
}
#SecondPic {
  -moz-transform: skew(-10deg, 10deg);
  -webkit-transform: skew(-10deg, 10deg);
  -o-transform: skew(-10deg, 10deg);
  -ms-transform: skew(-10deg, 10deg);
  transform: skew(-10deg, 10deg);
  background-color: #fff;
  height: 200px !important;
  width: 60% !important;
  border-radius: 20px;
  margin-top: 10rem;
  background-image: url("../assets/images/lpic-2.jpg");
  background-size: cover;
  background-position: top;
  border: 2px solid #eee;
  z-index: 20;
  -webkit-box-shadow: 8px 17px 21px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 17px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 17px 21px -10px rgba(0, 0, 0, 0.75);
}
h1 {
  font-size: 300%;
  margin-top: 5rem;
  margin-bottom: 3rem;
}
h3 {
  font-size: 200%;
}
#FirstContainer .timg {
  padding: 5rem 0;
  flex-direction: column;
}
#FirstContainer .display-3 {
  font-family: "Galada", cursive !important;
}
#FirstContainer img {
  height: 200px;
}

/* media queries !!!!! */
/* media queries for big screen */
@media (min-width: 1200px) {
  #showCase {
    height: 100vh !important;
  }
}
/* media query for ipads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  #showCase #Left img {
    height: 275px !important;
  }
}
/* media query for other devices */
@media (max-width: 900px) {
  #showCase #Left {
    padding: 2vw;
    padding-top: 5vw;
  }
  #showCase #Left img {
    height: 175px;
  }
  #showCase #Right {
    margin-bottom: 15vw;
  }
  #showCase #FirstPic {
    height: 170px !important;
    margin-top: 0.5rem !important;
  }
  #showCase #SecondPic {
    height: 190px !important;
    margin-top: 1rem !important;
  }
  #FirstContainer .timg {
    padding: 5rem 1rem;
  }
  #FirstContainer img {
    height: 150px;
    width: 100%;
  }
}
</style>
