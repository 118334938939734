<template>
<div id="WrapperCom" :class="WrapperBg">
    <router-view></router-view>
</div>
</template>

<script>
export default {

    name: 'wrapperComponent',

    computed: {
        WrapperBg(){
            if(this.$route.name == "welcome"){
                return 'primary'
            }else if(this.$route.name == "postlist"){
                return 'teal darken-1'
            }else if(this.$route.name == "coursewelcome" || this.$route.name == "eventlist"){
                return 'indigo darken-1'
            }
            else if(this.$route.name == "galleryoverview" || this.$route.name == "awardoverview"){
                return 'deep-purple darken-1'
            }
            else{
                return 'grey lighten-4'
            }
        }
    }

}
</script>

<style scoped>
#WrapperCom{
    min-height: 100vh;
    overflow: hidden;
    padding-bottom: 10rem !important;
}
</style>