<template>
  <div class="TopHeader">
    <!-- side bar on admin page -->
    <v-navigation-drawer
      v-model="drawer"
      v-if="indexPage()"
      :clipped="true"
      fixed
      dark
      app
      class="primary elevation-5 animated fadeIn slow"
    >
      <v-list>
        <v-list-item to="/home" router exact>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/students" router exact>
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Students</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/awards" router exact>
          <v-list-item-action>
            <v-icon>mdi-trophy-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Awards</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/courses" router exact>
          <v-list-item-action>
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/posts" router exact>
          <v-list-item-action>
            <v-icon>mdi-pen</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Posts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/events" router exact>
          <v-list-item-action>
            <v-icon>mdi-star-half</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Events</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/galleryhome" router exact>
          <v-list-item-action>
            <v-icon>mdi-google-photos</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gallery</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="UserIsAdmin" to="/users" router exact>
          <v-list-item-action>
            <v-icon>mdi-account-card-details</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- side bar on index page-->
    <v-navigation-drawer
      v-model="Welcomedrawer"
      :clipped="true"
      fixed
      class="indexNav elevation-5 d-flex d-xl-none d-lg-none animated fadeIn slow"
    >
      <v-list>
        <v-list-item @click="RouteClick('#Courses')" router exact>
          <v-list-item-action>
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#Events')" router exact>
          <v-list-item-action>
            <v-icon>mdi-star-half</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Events</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#AwardList')" router exact>
          <v-list-item-action>
            <v-icon>mdi-trophy-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Awards</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#Posts')" router exact>
          <v-list-item-action>
            <v-icon>mdi-pen</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Articles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#Modes')" router exact>
          <v-list-item-action>
            <v-icon>mdi-heart-half-full</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Learning Culture</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#AboutUs')" router exact>
          <v-list-item-action>
            <v-icon>mdi-information</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="RouteClick('#ContactUs')" router exact>
          <v-list-item-action>
            <v-icon>mdi-mail</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/login" router exact>
          <v-list-item-action>
            <v-icon>mdi-telegram</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="true"
      class="animated fadeIn slow"
      fixed
      app
      elevate-on-scroll
      :inverted-scroll="!welcomePage()"
      height="70px"
    >
      <v-app-bar-nav-icon v-if="indexPage() && !drawer" @click.stop="drawer = !drawer" />
      <v-app-bar-nav-icon
        v-if="!indexPage()"
        @click.stop="Welcomedrawer = !Welcomedrawer"
        class="d-flex d-xl-none d-lg-none"
      />

      <v-toolbar-title
        v-if="$router.currentRoute.name != 'welcome'"
        @click="$router.push('/')"
        class="pa-5"
      >
        <img src="../../assets/images/kalalaya-pic.png" alt="kalalaya" style="height: 55px" />
      </v-toolbar-title>

      <v-toolbar-title v-else @click="RouteClick('#showCase')" class="pa-5">
        <img src="../../assets/images/kalalaya-pic.png" alt="kalalaya" style="height: 55px" />
      </v-toolbar-title>

      <template v-if="!indexPage()">
        <v-toolbar-items class="d-none d-lg-flex d-xl-flex">
          <v-btn text @click="RouteClick('#AboutUs')">About</v-btn>
          <v-btn text @click="RouteClick('#Courses')">Courses</v-btn>
          <v-btn text @click="RouteClick('#Events')">Events</v-btn>
          <v-btn text @click="RouteClick('#AwardList')">Awards</v-btn>
          <v-btn text @click="RouteClick('#Posts')">Articles</v-btn>
          <v-btn text @click="RouteClick('#Modes')">Learning Culture</v-btn>
          <v-btn text @click="RouteClick('#ContactUs')">Contact</v-btn>
        </v-toolbar-items>
      </template>

      <v-spacer />

      <v-toolbar-items>
        <v-btn text @click="LoginClick()" v-if="!LoggedIn" class="d-none d-lg-flex d-xl-flex">Login</v-btn>
        <v-btn text @click="Logout" v-if="LoggedIn">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>


<script>
// imports
import firebase from "firebase/app";
import "firebase/auth";

// scripts
export default {
  name: "TopHeader",

  components: {},

  created() {
    if (screen.width > 1000) {
      this.drawer = true;
      this.Welcomedrawer = false;
    }
    // check if user is logged in
    firebase.auth().onAuthStateChanged(user => {
      this.LoggedIn = !!user;
    });
  },

  computed: {
    UserIsAdmin() {
      return this.$store.getters.UserIsAdmin;
    }
  },

  data() {
    return {
      LoggedIn: false,
      drawer: false,
      Welcomedrawer: false
    };
  },

  methods: {
    // logout method
    Logout() {
      firebase
        .auth()
        .signOut()
        .then(succ => {
          this.$router.push("/login");
        });
    },

    LoginClick() {
      window.scrollTo(0, 0);
      this.$router.push("/login");
    },

    RouteClick(route) {
      if (this.$router.currentRoute.name != "welcome") {
        this.$router.push("/");
        setTimeout(() => {
          this.$vuetify.goTo(route, { duration: 0 });
        }, 1000);
      } else {
        route == "#showCase"
          ? null
          : this.$vuetify.goTo(route, { duration: 0 });
      }
    },

    indexPage() {
      if (
        this.$route.name == "welcome" ||
        this.$route.name == "login" ||
        this.$route.name == "postlist" ||
        this.$route.name == "postwelcome" ||
        this.$route.name == "coursewelcome" ||
        this.$route.name == "eventwelcome" ||
        this.$route.name == "eventlist" ||
        this.$route.name == "galleryoverview" ||
        this.$route.name == "awardoverview"
      ) {
        return false;
      } else {
        return true;
      }
    },

    welcomePage() {
      if (this.$route.name == "welcome") {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.TopHeader .v-app-bar {
  z-index: 100 !important;
  background-color: rgba(230, 230, 255, 0.95);
}
.TopHeader .indexNav,
.TopHeader .v-navigation-drawer {
  z-index: 101;
  background-color: rgba(230, 230, 255, 0.95);
}
</style>